<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Dimensional Analysis</h2>
      <p class="mb-3">
        Dimensional analysis is a powerful problem solving tool that uses units to guide
        calculations. For instance, if we are trying to find mass and we have density and volume,
        you may think to yourself, "Do I multiply these quantities, do I divide them, and if I
        divide them, which would be in the numerator and which would be in the denominator?" If you
        use the units to guide you, you don't need to memorize all sorts of formulas. Since we want
        mass, we know we are looking for some value in grams, g, or kilograms, kg. That tells us
        that our final units have to be some form of grams. We know the density which we'll say has
        units of g/mL and we know the volume which we'll say is given in mL. From these units alone,
        we can see that we must multiply the density by the volume to obtain the appropriate units.
        Take a look at this below:
      </p>

      <p class="mb-5 pl-6">
        <stemble-latex
          content="$\text{g} = \displaystyle{\sout{\text{mL}} \times \frac{\text{g}}{\sout{\text{mL}}}}$"
        />
      </p>

      <p class="mb-2">
        Let's take a look at an example involving a bunch of made up units. Suppose that there are 8
        knicks to a knack, 20 knacks for every 3 ticks, and 10 ticks per 7 tacks. If you have 34
        knicks, how many tacks would you have? The general strategy is to first consider what value
        you are looking for. In this case, that is the number of tacks. We'll often write this down
        as:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\textbf{Wanted}\text{: \# of tacks}$" />
      </p>

      <p class="mb-2">
        Next, we read through the question and determine any and all values listed. These can be
        simple values or they can be equalities (i.e. this for every that, this per that, etc.) For
        any equality, instead of writing, this per that, we will write this = that. In this case we
        have the following information:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\textbf{Known} \text{: 8 knicks = 1 knack} \\
                    \hspace{1.4cm} \text{20 knacks = 3 ticks} \\
                    \hspace{1.4cm} \text{10 ticks = 7 tacks} \\
                    \hspace{1.4cm} \text{34 knicks}$"
        />
      </p>

      <p class="mb-2">
        The final step is to set up an expression that will result in the cancellation of all units
        except the desired one. Here, we are looking for tacks. so we'll start by writing:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\text{\# of tacks = }$" />
      </p>

      <p class="mb-2">
        If you ever have a piece of information that is not an equality, you want to start with that
        piece of information first. Looking at our list of Known information, the only thing that
        isn't an equality is the 34 knicks. So we'll start by writing that down:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex content="$\text{\# of tacks = 34 knick }\times \dots$" />
      </p>

      <p class="mb-2">
        Since knicks is not in the desired unit, we have to find an equality that will cancel out
        the unit of knicks. In this case, that is the 8 knicks to every knack. Any equality will be
        represented as a fraction with the unit that must be cancelled in the denominator while the
        other unit will be in the numerator.
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\text{\# of tacks = 34 }\sout{\text{knick}}
          \times \displaystyle{\frac{\text{1 knack}}{8 \sout{\text{ knick}}}} \times \dots$"
        />
      </p>

      <p class="mb-2">
        Now this process is repeated until you have the units remaining match that of what you want!
        Following this process we end up with the following:
      </p>

      <p class="pl-6 mb-5">
        <stemble-latex
          content="$\text{\# of tacks = 34 }\sout{\text{knick}}
            \times \displaystyle{\frac{1 \sout{\text{ knack}}}{8 \sout{\text{ knick}}}}
            \times \displaystyle{\frac{3 \sout{\text{ tick}}}{20 \sout{\text{ knack}}}}
            \times \displaystyle{\frac{\text{7 tack}}{10 \sout{\text{ tick}}}}
            =\text{ 0.44625 tack}
            $"
        />
      </p>

      <p class="mb-5">
        Looking at this expression, we can see that all the units have cancelled to give us the unit
        of tack that we are looking for. So this tells us that 34 knicks is equivalent to 0.44625
        tacks.
      </p>

      <p class="mb-5">
        Using dimensional analysis, solve each of the problems listed below. Express all answers to
        3 significant figures.
      </p>

      <p class="mb-2">
        a) Let's assume that you have
        <number-value :value="valA" />
        <stemble-latex content="$\text{cups of flour.}$" />
        If it requires
        <stemble-latex content="$\text{1.5 cups of flour}$" />
        to make a single layer of a cake, how many
        <stemble-latex content="$\text{4-layer cakes}$" />
        could you make assuming you have an unlimited amount of all other ingredients. Note: in
        theory, if you only had 2 cups of flour, you could only make 1 single layer so you couldn't
        make any four-layer cakes, but assume that you can have non-integers for both the number of
        layers, and the number of 4-layer cakes (i.e. do not round your values down).
      </p>

      <calculation-input
        v-model="inputs.da1"
        class="mb-6"
        dense
        :prepend-text="'$\\text{Number:}$'"
        :append-text="'$\\text{4-layer cakes}$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        b) Unit conversions are also accomplished quite nicely with dimensional analysis. If a
        material has a density of
        <number-value :value="valB" unit="\text{lb/ft}^3\text{,}" />
        what would its density be in
        <stemble-latex content="$\text{g/cm}^3\text{?}$" />
        Note that there are 12 in/ft, 2.54 cm/in, and 2.2 lb/kg. Any other required conversions are
        based on the metric system and you should be aware of these.
      </p>

      <calculation-input
        v-model="inputs.da2"
        class="mb-6"
        dense
        :prepend-text="'$\\text{Density:}$'"
        :append-text="'$\\text{g/cm}^3$'"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        c) Light-years are commonly confused as a measurement of time, instead of the actual meaning
        which is a distance. One light year is the distance that light travels in 1 Julian year
        (365.25 days). What is the distance in kilometers of
        <number-value :value="valC" unit="\text{lightyears?}" />
      </p>

      <calculation-input
        v-model="inputs.da3"
        class="mb-5"
        dense
        :prepend-text="'$\\text{Distance:}$'"
        :append-text="'$\\text{km}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question354',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        da1: null,
        da2: null,
        da3: null,
      },
    };
  },
  computed: {
    valA() {
      return this.taskState.getValueBySymbol('valA').content;
    },
    valB() {
      return this.taskState.getValueBySymbol('valB').content;
    },
    valC() {
      return this.taskState.getValueBySymbol('valC').content;
    },
  },
};
</script>
